import React from 'react'

import './widget.scss'

const Widget = ({
    widgetClass = "",
    widgetTitle = "",
    widgetText = "",
    widgetBackgroundImgURL = "",
    emptyWidgetBackground = false,
    componentToDraw = []
}) => {
    if (!emptyWidgetBackground) {
        return (
            <div className={`site-widget ${widgetClass}`} style={{ 
                backgroundImage: `url('${widgetBackgroundImgURL}')`
            }}>
                <p className="site-widget-header">{widgetTitle}</p>
                <p className="site-widget-description">{widgetText}</p>
            </div>
        )
    }
    else {
        return (
            <div className={`site-widget-empty ${widgetClass}`}>
                <p className="site-widget-empty-header">{widgetTitle}</p>
                <p className="site-widget-empty-description">{widgetText}</p>
                <div className="site-widget-component">
                    {componentToDraw}
                </div>
            </div>
        )
    }
}

export default Widget;
import React, { useState, useEffect } from 'react';

import axios from 'axios';

import { useParams } from 'react-router';
import './Gallery.scss'

const GalleryImages = () => {
    const [ images, SetImages ] = useState([]);
    const { folder } = useParams();

    useEffect(() => {
        axios.get(`/images?folder=${folder}`).then(res => {
            const data = res.data;
            const nData = data.split(', ');
            for (let photo of nData) {
                if (photo === "")
                  continue;
                const newLink = `/images/${folder}/${photo}`;
                SetImages(oldArray => [...oldArray, newLink ]);
            }
        }).catch(e => console.log(`Fetch Error (${folder}): ${e}`));

    }, [folder]);

    const toggleFullRes = index => {
        const bg = document.querySelector(".active-image").classList;
        const bgimg = document.querySelector(".active-image-img img");
        const curImage = images[index];
        console.log(bgimg);
        bg.toggle("active");
        bgimg.src = `${curImage}`;
    }

    const drawAllImages = () => {
        return images.map((src, i) => <img onClick={() => toggleFullRes(i)} src={src} alt={src} key={src}></img>);
    }

    return (
        <div>
            <nav className="navbar">
                <a href="#/galeria/">Powrót</a>
                <p>Kliknij obraz aby powiększyć</p>
                <p>Wolak Bud</p>
            </nav>

            <div className="gallery-container">
                {drawAllImages()}
            </div>

            <div className="active-image" onClick={() => toggleFullRes(0)}>
                <div className="active-image-img"><img src="" alt="0"></img></div>
            </div>
        </div>
    )
}

export default GalleryImages;
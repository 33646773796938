import React from 'react'

import './header.scss'

const Header = ({
    headerText1 = "",
    headerText2 = ""
}) => {

    const toggleNavbar = () => {
        const navbar = document.querySelector("div.site-header-navbar");
        const openBut = document.querySelector("span.nav-open-but");
        const closeBut = document.querySelector("span.nav-close-but");

        navbar.classList.toggle("open");
        openBut.classList.toggle("open");
        closeBut.classList.toggle("open");
    }

    const scrollTo = classElement => {
        document.querySelector(`.${classElement}`).scrollIntoView({
            behavior: "smooth"
        });
        
        const navbar = document.querySelector("div.site-header-navbar");
        const openBut = document.querySelector("span.nav-open-but");
        const closeBut = document.querySelector("span.nav-close-but");
        if (navbar.classList.contains("open") && openBut.classList.contains("open") && closeBut.classList.contains("open")) {
            navbar.classList.toggle("open");
            openBut.classList.toggle("open");
            closeBut.classList.toggle("open");
        }
    }

    return (
        <div>
            <header className="site-header">
                <span className="site-header-background"></span>
                <div className="site-header-left-top">
                    <img src="/images/WB-White.png" className="site-header-logo" alt="logo" />
                    <div className="site-header-name">Wolak Bud</div>
                </div>
                
                <span className="nav-open-but" onClick={() => toggleNavbar()}></span>
                <span className="nav-close-but" onClick={() => toggleNavbar()}></span>
                <div className="site-header-navbar">
                    <nav>
                        <ul>
                            <li><p onClick={() => scrollTo("onas")}>O nas</p></li>
                            <li><p onClick={() => scrollTo("projekty")}>Projekty</p></li>
                            <li><p onClick={() => scrollTo("oferta")}>Oferta</p></li>
                            <li><p onClick={() => scrollTo("cennik")}>Cennik</p></li>
                            <li><p onClick={() => scrollTo("kontakt")}>Kontakt</p></li>
                        </ul>
                    </nav>
                </div>

                <div className="site-header-left-box">
                    <p>{headerText1}</p>
                    <button className="site-button" onClick={() => scrollTo("kontakt")}>Napisz do Nas</button>
                </div>
            </header>

            <div className="site-second-header">
                <p>{headerText2}</p>
                <span className="site-second-arrow-down" onClick={() => scrollTo("onas")}></span>
            </div>
        </div>
    )
};

export default Header;
import axios from 'axios';
import React, { useEffect, useState } from 'react'

import './prices.scss'

const Prices = () => {
    const [ jsonData, SetJsonData ] = useState({});

    useEffect(() => {
        axios.get('/table-data').then(res => SetJsonData(res.data)).catch(e => console.log(`Error: ${e}`));
    }, []);

    const drawTable = () => {
        let celss = [];

        for (let i = 0; i < jsonData.columnsSize; i++) {
            celss.push({
                Text: jsonData.columns[i].Text,
                Unit: jsonData.columns[i].Unit,
                Price: jsonData.columns[i].Price,
            })
        }

        const map = celss.map(e => {
            const unit = e.Unit === 'M2' ? <span>M<sup>{e.Unit[1]}</sup></span> : e.Unit === 'M3' ? <span>M<sup>{e.Unit[1]}</sup></span> : e.Unit;
            return (
                <tbody key={e.Text}>
                    <tr>
                            <td>{e.Text}</td>
                            <td>{unit}</td>
                            <td>{e.Price}zł</td>
                    </tr>
                </tbody>
            )
        });
        return (
            <table>
                {map}
            </table>
        )
    }
    
    return (
        <div className="prices-container">
            <p> Proszę o kontakt poprzez formularz, odpowiemy najszybciej jak to możliwe. Po przesłanym projekcie wyślemy wstępną wycenę.</p>
            <div className="prices-container-element">
                {drawTable()}
            </div>
            <p style={{fontWeight: 500}}>Oczywiście zajmujemy się również pracami, które nie są zawarte w cenniku. 
                Ceny usług są cenami netto bez materiałów. </p>
            <p className="small-text">
                Cennik zawiera ceny najważniejszych usług budowlanych i ma charakter czysto informacyjny. Ceny usług budowlanych uzależnione są od szeregu czynników m.in: rodzaj prac, wielkość, czas realizacji i zróżnicowanie itp. Ceny oczywiście mogą podlegać indywidualnym negocjacjom. Wyceny drogą e-mailową są wstępne, informacyjne, mogą się zmienić po oględzinach obiektu. Ostateczna wartość każdego remontu określana jest zawsze w kosztorysie po spotkaniu na miejscu budowy.
                Podane na stronie ceny są wyłącznie informacją handlową i nie stanowią oferty w myśl art.66 § 1. Kodeksu Cywilnego.
            </p>
        </div>
    );
}

export default Prices;
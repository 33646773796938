import React from 'react'
import { Link } from 'react-router-dom'

import './imagesCarousel.scss'

const ImageCarousel = ({
    ImagesArray = []
}) => {
    let currentIndex = 0;
    const minSize = 0;
    const maxSize = ImagesArray.length - 1;

    const decreaseIndex = () => {
        if (currentIndex === minSize)
            return;

        let newNumber = currentIndex + 1;

        const selector = document.querySelector(".carousel-images");
        const imgsSelector = document.querySelectorAll(".carousel-images img");
        const radioSelector = document.getElementById(`radio${Math.abs(newNumber)}`);
        const imageSize = imgsSelector[0].clientWidth;
            console.log(imageSize);
        selector.style.transform = `translateX(${newNumber * imageSize}px)`;
        radioSelector.checked = true;

        currentIndex++;
    }

    const increaseIndex = () => {
        if (currentIndex === -maxSize)
            return;

        let newNumber = currentIndex - 1;

        const selector = document.querySelector(".carousel-images");
        const imgsSelector = document.querySelectorAll(".carousel-images img");
        const radioSelector = document.getElementById(`radio${Math.abs(newNumber)}`);
        const imageSize = imgsSelector[0].clientWidth;

        selector.style.transform = `translateX(${newNumber * imageSize}px)`;
        radioSelector.checked = true;

        currentIndex--;
    }

    const selectedIndex = index => {
        const selector = document.querySelector(".carousel-images");
        const imgsSelector = document.querySelectorAll(".carousel-images img");
        const imageSize = imgsSelector[0].clientWidth;

        selector.style.transform = `translateX(${-index * imageSize}px)`;

        currentIndex = -index;
    }

    const drawImages = () => {
        return ImagesArray.map(img => <img src={img} alt={img} key={img}/>);
    }

    const drawRadioButtons = () => {
        return ImagesArray.map((img, index) => <input type="radio" key={index} defaultChecked={index === 0} id={`radio${index}`} name="radiobuts" onClick={() => selectedIndex(index)}></input>)
    }

    return (
        <div className="image-carousel-container">
            <div className="image-carousel">
                <span className="carousel-left-button" onClick={() => decreaseIndex()}></span>
                <span className="carousel-right-button" onClick={() => increaseIndex()}></span>
                <div className="carousel-images">
                    { drawImages() }
                </div>
                <div className="carousel-radio-buttons">
                    { drawRadioButtons() }
                </div>
            </div>
            <Link to="/galeria">Wszystkie zdjęcia tutaj</Link>
        </div>
    );
};

export default ImageCarousel;
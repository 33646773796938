import React, { createRef } from 'react'
import  ReCAPTCHA from 'react-google-recaptcha'

import './contact.scss'

const Contact = () => {

    const recaptchaRef = createRef();

    const checkRecaptcha = ev => {
        if (recaptchaRef.current.getValue() === "") {
            alert("ReCaptcha jest wymagana.");
            ev.preventDefault();
            return;
        }
    }

    return (
        <form className="contact-form" action='/contact-form' method="post" encType="multipart/form-data" onSubmit={checkRecaptcha}>
            <input type="text" name="fname" placeholder="Imię..." required></input>
            <input type="text" name="lname" placeholder="Nazwisko..." required></input>
            <input type="email" name="email" placeholder="Email..." required></input>
            <textarea name="content" placeholder="Treść..." required></textarea>
            <p className="contact-file-text">Rekomendowana Ilość Zdjęć: 7 (dowolny format)</p>
            <input type="file" name="images" accept="image/*" multiple max="7"/>
            <ReCAPTCHA 
                sitekey="6Le6CZ8aAAAAABka_1DL0UGNeh3BbF1Vqf1HHq6o"
                ref={recaptchaRef}
                id="recaptcha"
                onChange={(token)=>{console.log(token);}}
                onExpired={() => {
                    recaptchaRef.current.reset();
                }}
                theme="light"
            />
            <input className="test" type="submit"></input>
            <p className="rodo-text">
                Poprzez wysłanie wiadomości, wyrażasz zgodę na przetwarzanie twoich danych osobowych przez Wolak Bud w celu odpowiedzi na zgłoszenie podane w formularzu.
            </p>
        </form>
    )
};

export default Contact;
import React from 'react'

import './footer.scss'

import Location from '../../Images/loc.png'
import Email from '../../Images/email.png'
import Phone from '../../Images/phone.png'
import List from '../../Images/list.png'

const Footer = () => {
    return (
        <div>
            <p className="contact-text">Zapraszamy do kontaktu!</p>
            <div className="bus-details">
                <div className="detail">
                    <img src={Location} alt="loc" />
                    <p>Ul. Biała 25B<br/> 58-124 Marcinowice</p>
                    
                </div>
                <div className="detail">
                    <img src={List} alt="nip" />
                    <p>Artur Wolak Bud<br/>NIP: 8822045470<br/>REGON: 364381017</p>
                </div>
                <div className="detail">
                    <img src={Email} alt="email" />
                    <a href="mailto:olakremonty@gmail.com">wolakremonty@gmail.com</a>
                </div>
                <div className="detail">
                    <img src={Phone} alt="phone" />
                    <a href="tel:+48 575-123-228">+48 575-123-228</a>
                </div>
            </div>
            <div className="down-footer">
                <p className="footer-element-left">Wolak Bud &copy; {new Date().getFullYear()}</p>
                <p className="footer-element-right">Projekt i Wykonanie: Kacper Poradnik</p>
            </div>
        </div>
    )
};

export default Footer;
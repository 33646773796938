import React, { useEffect, useState } from 'react'
import axios from 'axios';

import './globalStyle.scss'
import Header from './Components/Header/header'
import Widget from './Components/Widget/widget'
import Footer from './Components/Footer/footer'
import ImageCarousel from './Components/ImagesCarousel/imagesCarousel'
import Contact from './Components/Contact/contact'
import Prices from './Components/Prices/prices'

const defONasText = `
      Realizujemy zlecenia dla deweloperów, osób prywatnych, a także firm i innych instytucji.
      U nas dobry kontakt z klientem w cenie , staramy się każdemu dać pewność fachowo wykonanych usług i cenimy sobie porządek. 
      Każde zlecenie traktujemy indywidualnie starając się dotrzymać terminów zawartych w umowie z klientem.
      Zakres prac obejmują budynki mieszkalne, kamienice, domy jednorodzinne, a także wnętrza użytkowe przeznaczone pod biura, 
      sklepy, salony fryzjerskie, magazyny czy miejsca wypoczynku. Zamowienie potrzebnych materiałów może leżeć po na naszej stronie lub klienta. 
`;

const defOferta = `
      Nasza firma oferuje kompleksowe usługi wykończeniowe domów, lokali usługowych i mieszkań „pod klucz”. Działamy na terenie województwa dolnośląskiego.
      Zajmujemy się wykończeniem stanu deweloperskiego, nowych obiektów mieszkaniowych, starych mieszkań, domów i kamienic.
      Wykonujemy gładzie maszynowe i malowanie- wszystko wykonane metodą hydrodynamiczną.
      Oprócz prac budowlano-wykończeniowych oferujemy państwu pomoc przy robotach wyburzeniowych, sprzątających, skręcaniu-montażu mebli i przy usługach transportowych. 
`;

const defHeader1 = `
      Wolak Bud to firma remontowo-budowlana. Zajmujemy się kompleksowymi wykończeniami domów, mieszkań, lokali biurowych oraz remontowaniem i adaptacją pomieszczeń takich jak poddasza i piwnice.
`;

const defHeader2 = `
      Zapraszamy do kontaktu poprzez formularz w zakładce kontakt !
`;

const Image = "/images/OnasBG.JPG";
const Image2 = "/images/OfertaBG.JPG";

const App = () => {
  const [ onasText, SetOnasText ] = useState(defONasText);
  const [ ofertaText, SetOfertaText ] = useState(defOferta);
  const [ headerText1, SetHeaderText1 ] = useState(defHeader1);
  const [ headerText2, SetHeaderText2 ] = useState(defHeader2);

  const [ imagesArray, setImagesArray ] = useState([]);

  useEffect(() => {
    axios.get('/data/header-text.txt').then(res => SetHeaderText1(res.data)).catch(e => console.log(`Error while Fetching: ${e}`));
    axios.get('/data/header-text2.txt').then(res => SetHeaderText2(res.data)).catch(e => console.log(`Error while Fetching: ${e}`));
    axios.get('/data/oferta.txt').then(res => SetOfertaText(res.data)).catch(e => console.log(`Error while Fetching: ${e}`));
    axios.get('/data/onas.txt').then(res => SetOnasText(res.data)).catch(e => console.log(`Error while Fetching: ${e}`));
    
    axios.get('/images?folder=Galeria').then(res => {
      const data = res.data;
      const nData = data.split(', ');
      for (let photo of nData) {
        if (photo === "")
          continue;
        const newLink = `/images/Galeria/${photo}`;
        setImagesArray(oldArray => [...oldArray, newLink ]);
      }
    }).catch(e => alert(`Error: ${e}`));
  }, []);

  return (
    <div>
      <Header headerText1={headerText1} headerText2={headerText2} />
      <Widget widgetClass="onas" widgetTitle="O nas" widgetText={onasText} widgetBackgroundImgURL={Image} emptyWidgetBackground={false}/>
      <Widget widgetClass="projekty" widgetTitle="Projekty (Zdjęcia)" emptyWidgetBackground={true} componentToDraw={ImageCarousel({ ImagesArray: imagesArray })}/>
      <Widget widgetClass="oferta" widgetTitle="Oferta" widgetText={ofertaText} widgetBackgroundImgURL={Image2} emptyWidgetBackground={false}/>
      <Widget widgetClass="cennik" widgetTitle="Cennik" emptyWidgetBackground={true} componentToDraw={Prices()}/>
      <Widget widgetClass="kontakt" widgetTitle="Kontakt" widgetText="" emptyWidgetBackground={true} componentToDraw={Contact()}/>
      <Footer />
    </div>
  );
};

export default App;
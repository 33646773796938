import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import './Gallery.scss'

const Gallery = () => {
    const [ folders, SetFolders ] = useState([]);

    useEffect(() => {
        axios.get("/images/").then(res => {
            const data = res.data;
            const nData = data.split(', ');
            for (let folder of nData) {
                if (folder === "") continue;
                if (folder.search(".png") !== -1) continue; 
                if (folder.search(".JPG") !== -1) continue; 
                if (folder === "Galeria") continue;
                if (folder === "uploads") continue;

                SetFolders(oldArray => [...oldArray, folder]);
            }
        });
    }, []);

    const drawAllFolders = () => {
        return folders.map((folderName, i) => <Link to={`/galeria/${folderName}`} className="folder"><img src="/images/folder.png" alt="" /><p>{folderName}</p></Link>);  
    }

    return (
        <div>
            <nav className="navbar">
                <a href="/">Powrót</a>
                <p>Kliknij Folder aby Otworzyć</p>
                <p>Wolak Bud</p>
            </nav>

            <div className="gallery-container">
                {drawAllFolders()}
            </div>
        </div>
    )
}

export default Gallery;
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router, Route } from 'react-router-dom';
import App from './App';
import Gallery from './Galerry/Gallery'
import GalleryImages from './Galerry/GalleryImages';
import reportWebVitals from './reportWebVitals';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Route exact path="/" component={App} />
      <Route exact path="/galeria" component={Gallery} />
      <Route exact path="/galeria/:folder" component={GalleryImages} />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
